import React from "react";
import "./Landing.css"

export default class Landing extends React.Component {

    landingButtonPressed = (event) => {
        const destination = event.target.innerHTML;
        if (destination === "Login" || destination === "Dashboard") {
            if (localStorage.getItem("userId") && localStorage.getItem("accountType")) {
                if (localStorage.getItem("accountType") === "resident") {
                    window.location = "/Account";
                } else if (localStorage.getItem("accountType") === "concierge") {
                    window.location = "/Dashboard";
                }
            } else {
                window.location = "/Login";
            }
        } else if (destination === "Sign Up") {
            window.location = "/Signup";
        } else if (destination === "About") {
            window.location = "/About";
        } else if (destination === "Contact") {
            window.location = "/Contact";
        }
    }

    render() {
        let buttonTitle = "Login"
        if(localStorage.getItem("userId") != null) {
            buttonTitle = "Dashboard"
        }

        return(
            <div className="Landing">
                <div className="homeButton">🥡</div>
                <div className="loginBanner">
                    <h1 className="utensilsIcon">🍴</h1>
                    <h1 className="banner">At Your Door</h1>
                    <h1 className="bagIcon">🥡</h1>
                </div>

                <div className="landingArea">
                    <div className="landingButtonArea" id="landingLoginArea">
                        <button className="landingButton" onClick={this.landingButtonPressed}>{buttonTitle}</button>
                    </div>
                    <div className="landingButtonArea" id="landingSignUpArea">
                        <button className="landingButton" onClick={this.landingButtonPressed}>Sign Up</button>
                    </div>
                    <div className="landingButtonArea" id="landingAboutArea">
                        <button className="landingButton" onClick={this.landingButtonPressed}>About</button>
                    </div>
                    <div className="landingButtonArea" id="landingContactArea">
                        <button className="landingButton" onClick={this.landingButtonPressed}>Contact</button>
                    </div>
                </div>
            </div>
        )
    }
}