import React from "react";
import Parse from "parse";
import "./Signup.css";

export default class Signup extends React.Component {

    componentDidMount = () => {
        Parse.initialize(process.env.REACT_APP_APP_ID, process.env.REACT_APP_JS_ID); 
        Parse.serverURL = process.env.REACT_APP_SERVER_URL;
    }

    state = {
        username: "",
        password: "",
        emailAddress: "",
        phoneNumber: 0,
        apartmentNumber: 0
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const isValidPhoneNumber = this.validatePhoneNumber(this.state.phoneNumber);
        const isValidEmail = this.validateEmail(this.state.emailAddress);

        if (isValidEmail && isValidPhoneNumber ) {
            const phoneNumber = parseInt(this.state.phoneNumber.replace(/\D/g,''));
            const apartmentNumber = parseInt(this.state.apartmentNumber);

            const user = new Parse.User();
            user.set("username", this.state.username);
            user.set("residentName", this.state.username);
            user.set("password", this.state.password);
            user.set("email", this.state.emailAddress);
            user.set("phoneNumber", phoneNumber);
            user.set("apartmentNumber", apartmentNumber);
            user.set("accountType", "resident");
            user.set("buildingId", "vDC2xUPeJg");

            try {
                await user.signUp();

                alert("Sign up successful!");
                window.location = "/Account";
            } catch (error) {
                alert("Sign up unsuccessful. Please file a support ticket.");
                console.log("Error: " + error.code + " " + error.message);
            }
        }
    }

    validatePhoneNumber = (inputtxt) => {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (inputtxt.match(phoneno)) {
	        return true;      
        } else {
	        alert("Not a valid phone number");
	        return false;
        }
    }

    validateEmail = (inputText) => {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(inputText.match(mailformat)){
            return true;
        } else {
            alert("You have entered an invalid email address!");
            return false;
        }
    }

    render() {
        return(
            <div className="Signup">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <h1 className="signupBanner">Welcome to AtYourDoor!</h1>
                <div className="signupItems">
                    <div className="signupTitles">
                        <h5 id="suTitle">Username</h5>
                        <h5 id="suTitle">Password</h5>
                        <h5 id="suTitle">Email address</h5>
                        <h5 id="suTitle">Phone number</h5>
                        <h5 id="suTitle">Apartment Number</h5>
                    </div>
                    <div className="signupInputs">
                        <input onChange={this.handleChange} name="username" id="suInput" placeholder="johnDoe" autoComplete="off"/>
                        <input onChange={this.handleChange} name="password" id="suInput" placeholder="accountPass" autoComplete="off"/>
                        <input onChange={this.handleChange} name="emailAddress" id="suInput" placeholder="john.doe@gmail.com" autoComplete="off"/>
                        <input onChange={this.handleChange} name="phoneNumber" id="suInput" placeholder="111-111-1111" autoComplete="off"/>
                        <input onChange={this.handleChange} name="apartmentNumber" id="suInput" placeholder="100" autoComplete="off"/>
                    </div>
                </div>
                <button className="signupButton" onClick={this.handleSubmit}>Sign up</button>
            </div>);
    }
}