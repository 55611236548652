import React from "react";
import Login from "./Login/Login";
import AddDelivery from "./AddDelivery/AddDelivery";
import Dashboard from "./Dashboard/Dashboard";
import Signup from "./Signup/Signup";
import Landing from "./Landing/Landing";
import About from "./Info/About";
import Contact from "./Info/Contact";
import Account from "./Account/Account";
import Confirmation from "./Confirmation/Confirmation";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Parse from "parse"


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      accountType: ""
    }
  
    Parse.initialize(process.env.REACT_APP_APP_ID, process.env.REACT_APP_JS_ID); 
    Parse.serverURL = process.env.REACT_APP_SERVER_URL;
  }

  userLoggedIn = async(user) => {
    const admin = Parse.Object.extend("Admin");
    const query = new Parse.Query(admin);
    query.equalTo("objectId", user.attributes.buildingId);
    const buildingObj = await query.first();

    localStorage.setItem("userId", user.id);
    localStorage.setItem("username", user.attributes.username);
    localStorage.setItem("accountType", user.attributes.accountType);
    localStorage.setItem("paymentWindowOpen", buildingObj.attributes.isPaymentWindowOpen);

    if (user.attributes.accountType === "concierge") {
      window.location = "/Dashboard";
    } else if (user.attributes.accountType === "resident") {
      window.location = "/Account";
    }
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/*" element={<Landing />} />
          <Route path="/Login" element={<Login loggedIn={this.userLoggedIn}/>} />
          <Route path="/Add" element={<AddDelivery />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Confirmation" element={<Confirmation />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
