import React from "react";
import "./AddDelivery.css"
import Parse from "parse";

export default class AddDelivery extends React.Component {
    state = {
        recipient: {
            name: "",
            id: "",
            apartmentNumber: 0,
            phoneNumber: 0
        },
        service: "",
        notes: "",
        residents: [],
        filteredData: [],
        user: {}
    }

    async componentDidMount() {
        if (localStorage.getItem("accountType") !== "concierge") {
            window.location = "/";
        }

        const residents = await Parse.Cloud.run("getResidents", null);
        const userClass = Parse.Object.extend("User");
        const userQuery = new Parse.Query(userClass);
        userQuery.equalTo("objectId", localStorage.getItem("userId"));
        const user = await userQuery.first();
        this.setState({
            residents: residents,
            user: user
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleFilter = (event) => {
        // TODO: Causes warning but still works, maybe improve later?
        this.handleChange(event);
        const recipientSearch = event.target.value;
        const newFilter = this.state.residents.filter((value) => {
            const namesMatch = value.attributes.residentName.toLowerCase().includes(recipientSearch.toLowerCase())
            const numbersMatch = value.attributes.apartmentNumber.toString().includes(recipientSearch);
            return (namesMatch | numbersMatch);
        });

        if (recipientSearch === "") {
            this.setState({
                filteredData: []
            })
        } else {
            this.setState({
                filteredData: newFilter
            })
        }
    }

    residentClicked = (sender) => {
        const resident = this.state.residents.find(account => account.attributes.residentName === sender.target.title);
        if(resident.attributes.isFrozen) {
            alert("Resident's bill is overdue and their account is frozen.");
        } else {
            this.setState({
                recipient: {
                    name: resident.attributes.residentName,
                    id: resident.id,
                    apartmentNumber: resident.attributes.apartmentNumber,
                    phoneNumber: resident.attributes.phoneNumber
                },
                filteredData: []
            });
        }
    }

    addOrder = () => {
        const OrderObj = Parse.Object.extend("Order");
        const order = new OrderObj();

        order.set("recipientId", this.state.recipient.id);
        order.set("conciergeId", this.state.user.id);
        order.set("deliveryService", this.state.service);
        order.set("deliveryNotes", this.state.notes);
        order.set("apartmentNumber", this.state.recipient.apartmentNumber);
        order.set("residentName", this.state.recipient.name);
        order.set("conciergeName", this.state.user.attributes.username);
        order.set("paidFor", false);

        order.save().then((order) => {
            console.log("Order saved");

            this.textResident();

            window.location = "/Dashboard";
        }, (error) => {
            alert("Error saving order");
            console.log(error.message);
        });
    }

    textResident = async () => {
        const phoneString = "+1" + this.state.recipient.phoneNumber;
        const params = {phoneNumber: phoneString}
        await Parse.Cloud.run("textResident", params);
    }

    render() {
        return(
            <div className="AddDelivery">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <div className="RecipientSearch">
                    <div className="RecipientTitle"><h3>Recipient</h3></div>
                    <div className="search">
                        <div className="SearchInputs">
                            <input name="recipient" type="text" onChange={this.handleFilter} placeholder="Recipient Name" value={this.state.recipient.name} autoComplete="off"/>
                        </div>
                            <div className="dataResult">
                                {this.state.filteredData.slice(0,5).map((value, key) => {
                                    return (
                                        <div className="searchResult" onClick={this.residentClicked} key={key} title={value.attributes.residentName}>{value.attributes.residentName} - {value.attributes.apartmentNumber}</div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                <div className="ServiceBar">
                    <div className="ServiceTitle"><h3>Service</h3></div>
                    <div className="ServiceInputs">
                        <input name="service" type="text" onChange={this.handleChange} placeholder="Delivery Service" autoComplete="off"/>
                    </div>
                </div>
                <div className="DeliveryNotes">
                    <div className="DeliveryNotesTitle"><h3>Notes</h3></div>
                    <div className="DeliveryNotesArea">
                        <textarea name="notes" id="DeliveryNote" type="text" onChange={this.handleChange} placeholder="Notes"/>
                    </div>
                </div>
                <div id="LogDelivery">
                    <button id="logDeliveryButton" onClick={this.addOrder}>Log Delivery</button>
                </div>
            </div>
        );
    }
}