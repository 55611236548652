import React from "react";
import Parse from "parse";
import EasyTable from "react-easy-table";
import 'react-easy-table/src/theme/basic.scss';
import "./Account.css";

export default class Account extends React.Component {

    state = {
        orders: [],
        head: [],
        totalData: [],
        recipientId: ""
    }

    async componentDidMount() {
        if (localStorage.getItem("accountType") !== "resident") {
            window.location = "/";
        }
        
        const orderObj =  Parse.Object.extend("Order");
        const orderQuery = new Parse.Query(orderObj);
        const recipientId = localStorage.getItem("userId");
        orderQuery.equalTo("recipientId", recipientId);
        orderQuery.descending("createdAt");
        const orders = await orderQuery.find();

        const head = this.configureTableHead();
        const data = this.configureTableData(orders);

        this.setState({
            order: orders,
            head: head,
            totalData: data,
            recipientId: recipientId
        });
    }


    configureTableHead = () => {
        const head = [
            { title: "Concierge Name", assignTo: "conciergeName", id: "conciergeName" },
            { title: "Delivery Service", assignTo: "service", id: "service" },
            { title: "Delivery Notes", assignTo: "notes", id: "notes" },
            { title: "Delivery Date", assignTo: "date", id: "date" },
        ];
        return head;
    }

    configureTableData = (orders) => {
        const data = [];

        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            const orderInfo = order.attributes;

            const orderData = {
                conciergeName: orderInfo.conciergeName,
                service: orderInfo.deliveryService,
                notes: orderInfo.deliveryNotes,
                date: orderInfo.createdAt.toLocaleString(),
                id: orders[i].id
            }
            data.push(orderData);
        }
        return data;
    }

    navigateToLanding = () => {
        Parse.User.logOut();
        localStorage.clear();
        window.location = "/";
    }

    softNavigateToLanding = () => {
        window.location = "/";
    }

    navigateToTicket = () => {
        window.location = "/Contact";
    }

    navigateToPayment = async() => {
        const userQuery = new Parse.Query("User");
        userQuery.equalTo("objectId", this.state.recipientId);
        const user = await userQuery.first();
        
        user.set("openedPaymentLink", true);
        user.save().then((user) => {
            console.log("Success");
        }, (error) => {
            alert("There has been an error. Please file a support ticket.");
        });

        const Orders = Parse.Object.extend("Order");
        const query = new Parse.Query(Orders);
        query.equalTo("recipientId", this.state.recipientId);
        query.descending("createdAt");

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        query.greaterThan("createdAt", firstDay);
        const ordersThisMonth = await query.count();

        const params = {numOrders: ordersThisMonth}
        const paymentLink = await Parse.Cloud.run("getPaymentLink", params);
        window.location = paymentLink.url;
    }

    render() {
        let button;
        const window = localStorage.getItem("paymentWindowOpen");
        if(window === "true") {
            button = <button id="billButton" onClick={this.navigateToPayment}>Pay bill</button>
        } else {
            button = null;
        }

        return(
            <div className="Account">
                <div onClick={this.softNavigateToLanding} className="homeButton">🥡</div>
                <button id="signOutButton" onClick={this.navigateToLanding}>Sign out</button>
                <div className="dashUsername"><h2>Welcome {localStorage.getItem("username")}!</h2></div>
                <div id="accountTable" >
                    <EasyTable tHead={this.state.head} data={this.state.totalData}/>
                </div>
                <div id="serviceButtons">
                    {button}
                    <button id="supportButton" onClick={this.navigateToTicket}>File support ticket</button>
                </div>
            </div>
        );
    } 
}