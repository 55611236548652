import React from "react";
import Parse from "parse";
import LoginBanner from "../Login/LoginBanner";
import "./Confirmation.css";

export default class Confirmation extends React.Component {

    async componentDidMount() {
        const userQuery = new Parse.Query("User");
        userQuery.equalTo("objectId", localStorage.getItem("userId"));
        const user = await userQuery.first();
        
        user.set("visitedCompletionPage", true);
        user.save().then((user) => {
            window.location = "/Account";
        }, (error) => {
            alert("There has been an error. Please file a support ticket.");
        });
    }

    render() {
        return(
            <div className="Confirmation">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <LoginBanner />
                <div id="confirmationNote">
                    <h1>Thank you!</h1>
                </div>
            </div>
        );
    } 
}