import React from "react";

export default class LoginFlow extends React.Component {

    state = {
        usernameInput: "",
        passwordInput: ""
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.props.onSubmit({
            username: this.state.usernameInput,
            password: this.state.passwordInput
        });
    }

    render() {
        return(
            <form className="loginFlow">
                <div className="usernameInput" id="loginInputSection">
                    <label id="inputText">Username</label>
                    <input type="text" onChange={this.handleChange} id="usernameInput" autoComplete="off"/>
                </div>
                <div className="passwordInput" id="loginInputSection">
                    <label id="inputText">Password</label>
                    <input type="text" onChange={this.handleChange} id="passwordInput" autoComplete="off"></input>
                </div>
                <div className="button">
                    <button onClick={this.handleSubmit} id="button">Login</button>
                </div>
            </form>
        );
    }
}