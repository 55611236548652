import React from "react";
import LoginBanner from "./LoginBanner";
import LoginFlow from "./LoginFlow";
import "./Login.css";
import Parse from "parse";

export default class Login extends React.Component {
    state = {
        username: "",
        password: ""
    }

    componentDidMount = () => {
        Parse.initialize(process.env.REACT_APP_APP_ID, process.env.REACT_APP_JS_ID); 
        Parse.serverURL = process.env.REACT_APP_SERVER_URL;
    }

    handleAttempt = async (loginAttempt) => {
        try {
            const user = await Parse.User.logIn(loginAttempt.username, loginAttempt.password);
            if (user != null) {
                this.props.loggedIn(user);
            }
        } catch (error) {
            alert(error.message);
        }
    }

    render() {
        return(
            <div className="Login">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <LoginBanner />
                <LoginFlow onSubmit={this.handleAttempt}/>
            </div>
        );
    } 
}