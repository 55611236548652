import React from "react";
import "./About.css";

export default class About extends React.Component {
    render() {
        return(
            <div className="About">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <div className="loginBanner">
                    <h1 className="utensilsIcon">🍴</h1>
                    <h1 className="banner">At Your Door</h1>
                    <h1 className="bagIcon">🥡</h1>
                </div>
                <p id="generalAbout">AtYourDoor is a food delivery system for controlled-access
                    residential communities.</p>
                <div className="infoArea">
                    <div id="price">
                        <div className="infoHeader" id="priceHeader">Price</div>
                        <div className="infoBody" id="priceBody">AtYourDoor costs $1 per order and runs on a pay-as-you-go system which is billed monthly</div>
                    </div>
                    <div id="operations">
                        <div className="infoHeader" id="opsHeader">Hours</div>
                        <div className="infoBody" id="opsBody">AtYourDoor deliveries are available from 5pm-9am, while a concierge is on duty</div>
                    </div>
                    <div id="optional">
                        <div className="infoHeader" id="optionalHeader">Optional</div>
                        <div className="infoBody" id="optionalBody">AtYourDoor is entirely optional and users can cancel any time after paying any existing bills!</div>
                    </div>
                    <div id="tipping">
                        <div className="infoHeader" id="tippingHeader">Tipping</div>
                        <div className="infoBody" id="tippingBody">The majority of revenue is paid out to the concierge to pay them for their work and to eliminate the need to tip!</div>
                    </div>
                </div>
            </div>
        );
    } 
}