import React from "react";
import LoginBanner from "../Login/LoginBanner";
import Parse from "parse";
import "./Contact.css";

export default class Contact extends React.Component {

    state = {
        subject: "",
        description: ""
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    sendTicket = () => {
        const TicketObj = Parse.Object.extend("Ticket");
        const ticket = new TicketObj();

        ticket.set("subject", this.state.subject);
        ticket.set("description", this.state.description);
        ticket.set("userId", localStorage.getItem("userId"));

        ticket.save().then((serviceTicket) => {
            alert("Ticket saved");
            this.setState({
                subject: "",
                description: ""
            });

            window.location = "/";
        }, (error) => {
            alert("Error submitting ticket");
            console.log(error.message);
        });
    }

    render() {
        return(
            <div className="Contact">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <LoginBanner />

                <h3 id="generalContact">General inquiries: atyourdoorbusiness@gmail.com</h3>

                <div id="serviceTicket">
                    <h4 id="ticketDesc">Service Ticket</h4>

                    <div id="ticketSubject">
                        <p id="ticketHeader">Subject</p>
                        <input name="subject" onChange={this.handleChange} id="ticketSubjectInput"></input>
                    </div>

                    <div id="ticketDescription">
                        <p id="descriptionHeader">Description</p>
                        <textarea name="description" onChange={this.handleChange} id="ticketDescriptionArea"></textarea>
                    </div>

                    <button id="submitTicket" onClick={this.sendTicket}>Submit</button>
                </div>
            </div>
        );
    } 
}