import React from "react";
import Parse from "parse";
import EasyTable from "react-easy-table";
import "./Dashboard.css";
import 'react-easy-table/src/theme/basic.scss';

export default class Dashboard extends React.Component {

    state = {
        orders: [],
        head: [],
        totalData: [],
        filteredData: []
    }

    async componentDidMount() {
        if (localStorage.getItem("accountType") !== "concierge") {
            window.location = "/";
        }
        
        const orderObj =  Parse.Object.extend("Order");
        const orderQuery = new Parse.Query(orderObj);
        orderQuery.descending("createdAt");
        orderQuery.limit(10);
        const orders = await orderQuery.find();

        const head = this.configureTableHead();
        const data = this.configureTableData(orders);

        this.setState({
            order: orders,
            head: head,
            totalData: data,
            filteredData: data
        });
    }

    configureTableHead = () => {
        const head = [
            { title: "Apt #", assignTo: "apartmentNumber", id: "apartment_number" },
            { title: "Resident Name", assignTo: "residentName", id: "residentName" },
            { title: "Concierge Name", assignTo: "conciergeName", id: "conciergeName" },
            { title: "Delivery Notes", assignTo: "notes", id: "notes" },
            { title: "Delivery Service", assignTo: "service", id: "service" },
            { title: "Delivery Date", assignTo: "date", id: "date" },
        ];
        return head;
    }

    configureTableData = (orders) => {
        const data = [];

        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            const orderInfo = order.attributes;

            const orderData = {
                apartmentNumber: orderInfo.apartmentNumber,
                residentName: orderInfo.residentName,
                conciergeName: orderInfo.conciergeName,
                notes: orderInfo.deliveryNotes,
                service: orderInfo.deliveryService,
                date: orderInfo.createdAt.toLocaleString(),
                id: orders[i].id
            }
            data.push(orderData);
        }
        return data;
    }

    navigateToAddDelivery = () => {
        window.location = "/Add";
    }

    navigateToLanding = () => {
        Parse.User.logOut();
        localStorage.clear();
        window.location = "/";
    }

    handleTableSearch = (s) => {
        const rawResult = s.target.value;
        const filtered = this.state.totalData.filter((order) => {
            const aptMatch = order.apartmentNumber.toString().toLowerCase().includes(rawResult.toLowerCase());
            const namesMatch = order.residentName.toLowerCase().includes(rawResult.toLowerCase());
            return (aptMatch | namesMatch);
        })
        this.setState({
            filteredData: filtered
        });
    }

    render() {
        return(
            <div className="Dashboard">
                <div onClick={() => window.location="/"} className="homeButton">🥡</div>
                <button id="signOutButton" onClick={this.navigateToLanding}>Sign out</button>
                <h2 className="dashUsername">Welcome {localStorage.getItem("username")}!</h2>
                <input id="tableSearchInput" placeholder="Search" autoComplete="off" onChange={this.handleTableSearch}/>
                <div id="tableArea">
                    <EasyTable id="deliveryTable" tHead={this.state.head} data={this.state.filteredData} />
                </div>
                <button id="addDeliveryButton" onClick={this.navigateToAddDelivery}>Add delivery</button>
            </div>
        )
    }
}