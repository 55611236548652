import React from "react";

const LoginBanner = () => {
    return(
        <div className="loginBanner">
            <h1 className="utensilsIcon">🍴</h1>
            <h1 className="banner">At Your Door</h1>
            <h1 className="bagIcon">🥡</h1>
        </div>
    );
}

export default LoginBanner